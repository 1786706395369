@use "../variables";

.contact-page .content {
    .contact-information {
        > div {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;

            > div {
                display: flex;
                flex-direction: column;
                row-gap: 0.25rem;

                a span:first-child,
                > span span:first-child {
                    display: inline-block;
                }

                a,
                > span span:first-child {
                    font-size: larger;
                }

                a {
                    color: variables.$accent-color;
                    width: fit-content;

                    span:first-child {
                        width: 1rem;
                    }

                    span:nth-child(2) {
                        margin-left: 1rem;
                    }

                    &:nth-child(3) {
                        display: flex;

                        span:nth-child(2) {
                            display: inline-block;
                            text-decoration: underline;
                        }
                    }
                }

                > span {
                    span {
                        font-family: inherit;
                    }

                    span:not(:first-child) {
                        font-family: "Courier New", Courier, monospace;
                        font-size: larger;
                    }

                    span:first-child {
                        width: 7em;
                    }
                }
            }
        }
    }

    .teamviewer {
        > *:not(:last-child) {
            margin-bottom: 1rem;
        }

        h2 {
            text-align: center;
        }

        .buttons {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            column-gap: 1rem;
            row-gap: 1rem;

            a {
                color: white;
                background: variables.$accent-color-dark;
                padding: 1.5rem 0;
                font-size: x-large;
                border-radius: 0.5rem;
                text-decoration: none;
                transition: all 0.2s;
                width: 100%;
                max-width: 30rem;
                text-align: center;

                svg {
                    margin-right: 0.5rem;
                }

                &:hover {
                    background-color: darken(variables.$accent-color-dark, 5);
                }
            }
        }
    }

    .form-container {
        display: flex;
        flex-direction: column;

        form {
            width: 100%;
            max-width: 40rem;
            display: flex;
            flex-direction: column;
            align-self: center;

            > label {
                font-size: 1.1rem;
            }

            input,
            textarea,
            select,
            option,
            div label {
                font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
            }

            input,
            textarea,
            select {
                max-width: calc(100% - 1rem);
                padding: 0.5rem;
                font-size: large;
                border: none;
                border-radius: 0.25rem;
                background: darken(white, 5);

                &:focus {
                    outline: 1px solid variables.$accent-color-dark;
                }
            }

            textarea {
                min-width: calc(100% - 1rem);
                height: 8rem;
            }

            select {
                max-width: 100%;
            }

            label {
                margin: 0.75rem auto 0.5rem 0.5rem;

                h2 {
                    color: variables.$main-color-lighter;
                    font-size: large;
                }
            }

            button {
                width: calc(100% - 4rem);
                margin: 1rem 2rem 0 2rem;
                padding: 0.5rem;
                background: variables.$accent-color-dark;
                color: white;
                border: none;
                border-radius: 0.25rem;
                font-size: large;
                cursor: pointer;
                transition: all 0.4s;

                svg {
                    margin-right: 0.5rem;
                }

                &:hover {
                    background: darken(variables.$accent-color-dark, 5);
                }
            }

            hr {
                margin: 1.5rem 0 0.75rem 0;
                width: 90%;
                align-self: center;
            }

            div {
                width: 95%;
                display: flex;
                column-gap: 1rem;
                align-self: center;
                justify-content: center;

                input,
                input:focus-within {
                    width: 1rem;
                    outline: none;
                }

                label {
                    font-size: large;
                    color: white;
                }
            }
        }
    }

    @media only screen and (max-width: 1000px) {
        .contact-information > div {
            flex-direction: column;
            row-gap: 2rem;

            > div:nth-child(2) {
                row-gap: 1rem;

                > span {
                    span {
                        display: inline-block;

                        &:first-child {
                            width: 100%;
                        }

                        &:nth-child(2) {
                            width: calc(100% - 1em);
                            padding-left: 1em;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 600px) {
        .contact-information {
            & > div > div:nth-child(2) > span:not(:first-child) {
                font-size: large;
            }
        }

        .teamviewer .buttons a {
            font-size: large;
        }

        .vacancies > div h2 {
            font-size: large;
        }
    }
}
