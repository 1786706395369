@use "../variables";

.vacancies-page {
    .panel:not(.over-eye-catcher) {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;

        h2 {
            margin-bottom: 0.5rem;
        }

        ul {
            margin-left: 1.5rem;
            line-height: 1.6rem;
        }

        a {
            color: variables.$accent-color-dark;
        }

        &.vacancy {
            h1, h2 {
                font-family: Montserrat;
            }
        }
    }

    // OLD STUFF!
    .vacancies .vacancy {
        display: flex;
        flex-direction: column;
        row-gap: 1.5rem;

        background: variables.$background-color-light;
        padding: 1rem;
        border-radius: 0.5rem;

        .header {
            color: variables.$accent-color;
            font-size: xx-large;
        }

        ul {
            margin-top: 0.5rem;
            margin-left: 1.5rem;
            display: flex;
            flex-direction: column;
            row-gap: 0.25rem;
        }
    }
}