@use "../variables";

$transition-speed: 0.5s;

.dropdown-list {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    > h3:not(:first-of-type) {
        margin-top: 1rem;
    }

    .entry {
        > .title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            column-gap: 0.5rem;
            padding: 1rem;
            border-radius: 0.5rem;
            background: variables.$background-color-dark;
            cursor: pointer;
            transition: border-radius $transition-speed;

            span {
                display: flex;
                align-items: center;
                column-gap: 1rem;

                h2 {
                    transition: color .4s;

                    &::first-letter {
                        color: inherit;
                    }
                }

                svg {
                    width: 2rem;
                }
            }

            svg {
                font-size: xx-large;
                transition: transform $transition-speed;
                color: variables.$accent-color;
            }
        }

        > .entry-content {
            height: 0;
            overflow: hidden;
            margin: 0 0.5rem;
            border-radius: 0 0 0.5rem 0.5rem;
            background: darken(variables.$background-color, 2);
            transition: height $transition-speed;
            color: white;

            > div {
                padding: 1rem;
            }

            ul {
                margin-left: 1rem;
            }
        }

        &.active {
            .title > svg {
                transform: rotate(180deg);
            }

            h2 {
                color: variables.$accent-color;
            }
        }

        @media only screen and (max-width: 750px) {
            > .entry-content {
                margin: 0;
            }

            &.active > .title {
                border-radius: 0.5rem 0.5rem 0 0;
            }
        }
    }
}