@use '../variables';

$navbar-offset: .5em;

.logo-header {
    width: 100%;
    background: variables.$background-color-dark;
    border-bottom: 1px solid variables.$main-color;

    display: flex;
    justify-content: center;

    .ta-logo {
        width: 25em;
        margin: 1em;
    }
}

nav {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    justify-content: center;

    a {
        text-decoration: none;
        color: white;
        margin: .5em;
        font-family: Noxlock;
        font-size: 1.1em;
        border-bottom: 1px solid transparent;
        transition: color .3s, border-bottom .3s;

        &:hover {
            color: variables.$accent-color;
        }

        &.active {
            border-color: white;
        }

        &.active:hover {
            border-color: variables.$accent-color;
        }

        &::first-letter {
            color: variables.$accent-color;
        }
    }

    .regular-menu, .hamburger-menu {
        width: 100%;
        margin-top: 0;
        background: variables.$background-color;
        transition: all .3s;
    }

    .regular-menu {
        position: relative;
        display: flex;
        justify-content: center;
        z-index: 1;

        > div {
            width: calc(100% / 3);
            display: flex;
            justify-content: space-evenly;
            align-items: center;
        }

        .center a {
            visibility: hidden;
            opacity: 0;
            transition: all .2s;
            height: 2em;
            border: none;

            svg {
                height: 100%;
            
                & > path:nth-child(1), & > path:nth-child(3) {
                    fill: rgb(240, 240, 240);
                }
            }
        }

        .hamburger {
            display: none;
            font-size: 2em;
            color: white;
            cursor: pointer;

            &:not(.open) {
                .close-btn {
                    display: none;
                }
            }

            &.open {
                .open-btn {
                    display: none;
                }
            }
        }
    }

    .hamburger-menu {
        display: none;
        flex-direction: column;
        padding: .5em 0;
        row-gap: .5em;
        align-items: center;
        position: absolute;

        .first-letter {
            color: variables.$accent-color;
            font-family: Noxlock;
        }

        &:not(.open) {
            visibility: hidden;
            opacity: 0;
            top: 0;
        }

        &.open {
            visibility: unset;
            opacity: 1;
            top: 100%;
        }
    }

    &.floating {
        .regular-menu .center a {
            visibility: visible;
            opacity: 1;
        }

        .regular-menu, .hamburger-menu {
            width: calc(100% - $navbar-offset * 2);
            margin-top: .5em;
            background: rgba(0, 0, 0, 0.3);
            box-shadow: 0 .1em 1em rgba(0, 0, 0, 0.4);
            backdrop-filter: blur(10px);
            -webkit-backdrop-filter: blur(8px);
            border-radius: .5em;
        }
    }

    &:not(.floating) {
        .regular-menu {
            border-bottom: 1px variables.$main-color solid;
        }
    }
}

@media only screen and (max-width: 800px) {
    .logo-header {
        display: none !important;
    }

    nav {
        .regular-menu {
            .left, .right {
                display: none;
            }

            .center {
                width: 90%;
                
                a {
                    visibility: visible !important;
                    opacity: 1 !important;
                }
            }

            .hamburger {
                width: 12%;
                display: flex;
            }
        }

        .hamburger-menu {
            display: flex;
        }
    }
}