$background-color: #303030;
$background-color-dark: darken($background-color, 5);
$background-color-light: lighten($background-color, 2);
$background-color-lighter: lighten($background-color, 5);

$main-color: #666;
$main-color-dark: darken($main-color, 5);
$main-color-light: lighten($main-color, 10);
$main-color-lighter: lighten($main-color-light, 10);

$accent-color: #3cf;
$accent-color-dark: darken($accent-color, 5);
$accent-color-light: lighten($accent-color, 5);

$small-phone-width: 500px;
$phone-width: 650px;
$tablet-width: 900px;