@use '../variables';

.team-page {
    display: flex;
    justify-content: center;

    .team {
        width: 100%;
        max-width: 80em;
        display: flex;
        flex-direction: column;
        margin: 1em;
        row-gap: 1em;

        .member {
            width: 100%;
            max-width: 60rem;
            display: flex;
            border-radius: 1em;
            background: variables.$background-color-dark;

            img {
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                object-fit: cover;
                height: 20rem;
                width: 20rem;
                max-width: 100%;
                border-radius: .5rem;
                margin: .5rem;
                animation: 2s infinite alternate loading;
                
                &.contrasty {
                    filter: contrast(1.1) saturate(1.05);
                }
            }

            .details {
                width: 100%;

                h1, h2 {
                    margin: .5rem .25rem;
                }

                h1, p {
                    color: white;
                }

                h1 {
                    color: variables.$accent-color;
                }

                h2 {
                    color: variables.$main-color-lighter;
                    font-weight: 500;
                }

                p {
                    margin: 1rem;
                }
            }

            &:nth-child(even) {
                flex-direction: row-reverse;
                align-self: flex-end;

                h1, h2 {
                    text-align: right;
                }
            }
        }

        @media only screen and (max-width: 675px) {
            .member {
                flex-direction: column;

                img {
                    align-self: center;
                    margin: 1.5rem 0;
                }

                h1, h2 {
                    margin: .25rem .75rem !important;
                }

                h1 {
                    font-size: x-large;
                }

                h2 {
                    font-size: medium;
                }

                &:nth-child(even) {
                    flex-direction: column;

                    h1, h2 {
                        text-align: left;
                    }
                }
            }
        }
    }
}