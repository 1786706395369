@use '../variables';

footer {
    display: flex;
    width: 100%;
    padding: 1rem 0;
    border-top: 1px solid variables.$main-color;
    background: variables.$background-color-dark;
    margin-top: auto;

    > div {
        width: calc(100% / 3);
    }

    .left {
        display: flex;
        justify-content: center;
        align-items: center;

        .links {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 2em;

            a {
                text-decoration: none;
                color: white;
                margin: .5em;
                font-family: Noxlock;
                transition: color .3s, border-bottom .3s;

                .ta-logo {
                    height: 2em;
                }

                &:not(:first-child) {
                    border-bottom: 1px solid transparent;
                }

                &:hover {
                    color: variables.$accent-color;
                }

                &.active {
                    border-color: white;
                }

                &.active:hover {
                    border-color: variables.$accent-color;
                }

                &::first-letter {
                    color: variables.$accent-color;
                }
            }
        }
    }

    .center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        row-gap: 1em;

        a {
            color: variables.$accent-color;
        }

        div {
            text-align: center;
            display: flex;
            flex-direction: column;
        }
    }

    .mobile-right {
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 1rem;

        img {
            width: 6rem;
        }
    }

    .right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        row-gap: 1rem;

        .support-downloads {
            padding: 1.5em;
            border: 1px variables.$accent-color solid;
            border-radius: 1.5em;

            .buttons {
                margin-top: .7em;
                display: flex;
                justify-content: space-around;
                
                hr {
                    border: 1px variables.$main-color solid;
                }
                
                a {
                    text-decoration: none;
                    color: variables.$accent-color;
                    transition: color .3s;

                    &:hover {
                        color: variables.$accent-color-light;
                    }
                }
            }
        }

        .logos {
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 1rem;
            
            img {
                width: 5rem;
            }
        }
    }

    @media only screen and (max-width: 850px) {
        > div {
            width: 100%;
        }

        .left, .right {
            display: none;
        }

        .mobile-right {
            display: flex;
        }

        .center {
            margin: 1rem;
        }
    }
}