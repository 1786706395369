@use "../variables";

.home-page .content {
    .objective {
        h1 {
            margin-bottom: 0.75rem;
        }

        h1:last-of-type {
            margin-top: 1.5rem;
        }
    }

    .it-solutions {
        h3 {
            color: darken(white, 10);
            margin-bottom: 1em;
            font-weight: 500;
        }

        .icons {
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            justify-content: center;

            .nav-pic-rect {
                width: calc(25% - 1rem);
                font-size: 1.5rem;

                img {
                    filter: grayscale(1) brightness(80%) saturate(200%) contrast(1.5);
                    transition: filter .4s;
                }

                .free-pane {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    background: rgba(40, 162, 202, 0.226);
                    transition: opacity .4s;
                }

                &:hover {
                    img {
                        filter: none;
                    }

                    .free-pane {
                        opacity: 0;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 950px) {
        .partners {
            display: none;
        }

        .it-solutions .icons {
            .nav-pic-rect {
                font-size: 1.1rem !important;
                width: calc(33% - 1rem);
            }
        }
    }

    @media only screen and (max-width: 650px) {
        .it-solutions .icons {
            .nav-pic-rect {
                max-height: 6rem;
                width: calc(50% - 1rem);
            }
        }
    }
}
