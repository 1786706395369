.confirmation-page {
    width: 100%;
    height: 100%;
    text-align: center;

    .success-message {
        color: rgb(71, 255, 111);
        margin: 1em;
    }

    p {
        color: white;
    }
}