@use "../variables";

.nav-pic-rect {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;

    border-radius: 0.5rem;
    background: #fff1;

    text-decoration: none;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    > div {
        position: absolute;
        z-index: 2;
        width: 100%;

        display: flex;
        align-items: flex-end;
        background: linear-gradient(0, #0008 0, transparent 100%);

        span {
            margin: 0.5rem;
            font-weight: 500;
            color: white;
            text-decoration: underline solid transparent 2px;
            text-underline-offset: .25rem;
            transition: text-decoration .4s;
        }
    }
}
